export enum EventType {
  REQUEST_PENDING = 'request.pending',
  REQUEST_INVALID = 'request.invalid',
  VERIFICATION_CANCELLED = 'verification.cancelled',
  REQUEST_TIMEOUT = 'request.timeout',
  REQUEST_UNAUTHORIZED = 'request.unauthorized',
  VERIFICATION_ACCEPTED = 'verification.accepted',
  VERIFICATION_DECLINED = 'verification.declined',
  VERIFICATION_STATUS_CHANGED = 'verification.status.changed',
  REQUEST_DELETED = 'request.deleted',
  REQUEST_RECEIVED = 'request.received',
  REVIEW_PENDING = 'review.pending',
  REQUEST_DATA_CHANGED = 'request.data.changed',
}
