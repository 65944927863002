import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserEventsService {
  private userEventsSubject = new Subject<any>();
  public userEventsObservable = this.userEventsSubject.asObservable();
  private eventSource: EventSource | undefined;

  startListening(setTokenInCookie: (path: string) => Observable<void>): void {
    if (this.eventSource || localStorage.getItem('pdf-generation') === 'true') {
      return;
    }
    const url = `${environment.BACKEND_URL}/user-events`;
    const path = new URL(url, document.location.toString()).pathname;
    const eventSource = new EventSource(url, {
      withCredentials: true,
    }); // opens a Server-Sent Events (SSE) connection to receive live updates
    this.eventSource = eventSource; // store connection in eventSource
    setTokenInCookie(path).subscribe(() => {
      // handle errors and attempt reconnection
      eventSource.onerror = () => {
        eventSource.close();
        if (this.eventSource === eventSource) {
          this.eventSource = undefined;
          setTimeout(() => this.startListening(setTokenInCookie), 5000);
        }
      };
      // handle incoming SSE messages
      eventSource.onmessage = (event) => {
        console.log('New SSE event:', event.data);
        this.userEventsSubject.next(event.data);
      };
    });
  }

  // SSE endpoint with verification token guard for short-term users (stakeholders doing id verification)
  startListeningForVerificationStatusEvents(token: string): void {
    if (this.eventSource) {
      return;
    }

    const url = `${environment.BACKEND_URL}/user-events/verification-status`;
    const path = new URL(url, document.location.toString()).pathname;
    var date = new Date();
    date.setSeconds(date.getSeconds() + 10);
    document.cookie = `verificationToken=${
      token || ''
    }; expires=${date.toUTCString()}; path=${path}`;

    const eventSource = new EventSource(url, {
      withCredentials: true,
    });
    this.eventSource = eventSource;

    // handle errors and attempt reconnection
    eventSource.onerror = () => {
      eventSource.close();
      if (this.eventSource === eventSource) {
        this.eventSource = undefined;
        setTimeout(
          () => this.startListeningForVerificationStatusEvents(token),
          5000
        );
      }
    };
    // handle incoming SSE messages
    eventSource.onmessage = (event) => {
      console.log('New verification status SSE event:', event.data);
      this.userEventsSubject.next(event.data);
    };
  }

  stopListening(): void {
    this.eventSource?.close();
    this.eventSource = undefined;
  }
}
