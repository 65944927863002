<app-big-loader *ngIf="isLoading"></app-big-loader>
<ng-container *ngIf="!isLoading" [ngSwitch]="userType">
  <!-- PERSONAL -->
  <ng-container *ngSwitchCase="userTypes.PERSONAL">
    <app-onboarding-individual-form-new
      *ngIf="
        userState === userStates.VERIFIED_MOBILE_PHONE_NUMBER ||
        userState === userStates.EDD
      "
      [onboardingData]="onboardingData"
      [countries]="countries"
      [acceptedCountries]="acceptedCountries"
      [isReviewed]="userState === userStates.EDD"
      [showExtraQuestions]="
        (userProgram === Program.JADE && userState === userStates.EDD) ||
        userProgram === Program.EMERALD
      "
      [isEDD]="userState === userStates.EDD"
      [steps]="steps"
      [program]="userProgram"
      (reloadUser)="ngOnInit()"
    ></app-onboarding-individual-form-new>

    <!-- allow to see submitted onboarding form at the following userStates -->
    <app-onboarding-individual-submitted-new
      *ngIf="
        userState === userStates.DECISION_POINT ||
        userState === userStates.EDD_SUBMITTED
      "
      [onboardingData]="onboardingData"
      [countries]="countries"
      [steps]="steps"
      [isEDDSubmitted]="userState === userStates.EDD_SUBMITTED"
      [showExtraQuestions]="
        (userProgram === Program.JADE &&
          userState === userStates.EDD_SUBMITTED) ||
        userProgram === Program.EMERALD
      "
    ></app-onboarding-individual-submitted-new>
  </ng-container>

  <!-- CORPORATE -->
  <ng-container *ngSwitchCase="userTypes.CORPORATE">
    <app-onboarding-business-form-new
      *ngIf="
        userState === userStates.VERIFIED_MOBILE_PHONE_NUMBER ||
        userState === userStates.ADDITIONAL_INFORMATION_REQUESTED ||
        userState === userStates.EDD
      "
      [onboardingData]="onboardingData"
      [countries]="countries"
      [acceptedCountries]="acceptedCountries"
      [isReviewed]="
        userState === userStates.ADDITIONAL_INFORMATION_REQUESTED ||
        userState === userStates.EDD
      "
      [steps]="steps"
      [isEDD]="userState === userStates.EDD"
      [isFirstTimeEDD]="
        userState === userStates.EDD &&
        onboardingData?.corporateForm?.dtEddFormSubmitted === null
      "
      (reloadUser)="ngOnInit()"
    ></app-onboarding-business-form-new>

    <app-onboarding-business-submitted-new
      *ngIf="
        userState === userStates.DECISION_POINT ||
        userState === userStates.KEY_PERSONS_VERIFICATION ||
        userState === userStates.KEY_PERSONS_DECISION_POINT ||
        userState === userStates.BUSINESS_CDD_REVIEW ||
        userState === userStates.EDD_SUBMITTED
      "
      [onboardingData]="onboardingData"
      [countries]="countries"
      [steps]="steps"
    ></app-onboarding-business-submitted-new>
  </ng-container>
</ng-container>
