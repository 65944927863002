<mat-card
  fxLayoutAlign="space-between start"
  fxLayout.lt-sm="column"
  [formGroup]="otherFieldsGroup!"
>
  <div>
    <div *ngIf="restartProcess" class="note restart">
      <div fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>error_outline</mat-icon>
        <b
          >3 failed attempts, please restart the process for security reasons</b
        >
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
      <!-- LEFT COLUMN -->
      <div
        class="left-column"
        [fxFlexOrder.lt-sm]="isExternal ? 1 : 2"
        fxFlexOrder="1"
      >
        <div fxLayout="column">
          <label for="amount">Amount in account currency</label>
          <mat-form-field>
            <input
              matInput
              data-testid="amount-input"
              formControlName="amount"
              type="number"
              min="0"
              id="amount"
            />
            <div matTextSuffix>{{ currency }}</div>
            <mat-error *ngIf="amountControl.hasError('required')"
              >Required</mat-error
            >
            <mat-error *ngIf="amountControl.hasError('pattern')"
              >Use format: 0.00</mat-error
            >
            <mat-error *ngIf="amountControl.hasError('min')"
              >Amount has to be greater than 0</mat-error
            >
          </mat-form-field>
        </div>

        <div fxLayout="column" *ngIf="isExternal">
          <label for="toCurrency">Currency (exchange rate against EUR)</label>
          <mat-form-field>
            <mat-select
              placeholder="Please select"
              fxLayout="column"
              fxLayoutGap="14px"
              name="toCurrency"
              formControlName="toCurrency"
            >
              <mat-option
                *ngFor="let currency of currencyCodeList"
                [value]="currency"
              >
                <div fxLayout fxLayoutAlign="space-between center">
                  <div fxLayoutAlign="start center">
                    <img [src]="imagePath(currency.code)" />
                    {{ currency.code }}
                  </div>
                  <div>
                    {{ currency.rate }}
                  </div>
                </div>
              </mat-option>
              <mat-select-trigger>
                <div fxLayoutAlign="start center">
                  <img [src]="imagePath(toCurrencyControl?.value?.code)" />
                  {{
                    toCurrencyControl?.value
                      ? toCurrencyControl?.value?.code +
                        ':  ' +
                        toCurrencyControl?.value?.rate
                      : ''
                  }}
                </div>
              </mat-select-trigger>
            </mat-select>
          </mat-form-field>
          <div *ngIf="lastUpdated" class="last-updated">
            Last updated: {{ lastUpdated | date: 'dd/MM/yyyy HH:mm' }}
          </div>
        </div>

        <!-- if ('from' account currency === toCurrency), then don't show Transaction amount field because not needed -->
        <div
          fxLayout="column"
          *ngIf="
            isExternal &&
            transactionForm?.value?.accounts?.fromAccount?.ccy !==
              toCurrencyControl?.value?.code
          "
        >
          <label for="toAmount">Transaction amount</label>
          <mat-form-field>
            <input
              matInput
              formControlName="toAmount"
              type="number"
              min="0"
              id="toAmount"
            />
            <div matTextSuffix>{{ toCurrencyControl?.value?.code }}</div>
            <mat-error *ngIf="toAmountControl.hasError('required')"
              >Required</mat-error
            >
            <mat-error *ngIf="toAmountControl.hasError('pattern')"
              >Use format: 0.00</mat-error
            >
            <mat-error *ngIf="toAmountControl.hasError('min')"
              >Amount has to be greater than 0</mat-error
            >
          </mat-form-field>
        </div>

        <div fxLayout="column" *ngIf="isExternal">
          <label for="executionDate">Value Date</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              [matDatepicker]="picker"
              [matDatepickerFilter]="dateFilter"
              name="executionDate"
              formControlName="executionDate"
              readonly
              [min]="today"
              [max]="maxValueDate"
              data-testid="value-date-input"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
              data-testid="value-date-picker-input"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="executionDateControl?.hasError('required')"
              >Required</mat-error
            >
          </mat-form-field>
        </div>

        <div fxLayout="column">
          <label for="description">{{
            !isBetween ? 'Description for Payer' : 'Description'
          }}</label>
          <mat-form-field>
            <textarea
              matInput
              #payerDescInput
              formControlName="payerDescription"
              id="description"
              data-testid="payer-description-input"
              [maxlength]="descriptionMaxLength"
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="1"
            ></textarea>
            <mat-error *ngIf="payerDescriptionControl.hasError('required')"
              >Required</mat-error
            >
            <mat-error *ngIf="payerDescriptionControl.hasError('pattern')"
              >Use only letters and numbers</mat-error
            >
            <div class="counter">
              <label
                >{{ payerDescInput.value.length }} /
                {{ descriptionMaxLength }}</label
              >
            </div>
          </mat-form-field>
        </div>

        <div fxLayout="column" *ngIf="beneficiaryDescriptionControl">
          <label for="description">Description for Beneficiary</label>
          <mat-form-field>
            <textarea
              matInput
              #benDescInput
              formControlName="beneficiaryDescription"
              id="description"
              data-testid="beneficiary-description-input"
              [maxlength]="descriptionMaxLength"
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="1"
            ></textarea>
            <mat-error
              *ngIf="beneficiaryDescriptionControl?.hasError('required')"
              >Required</mat-error
            >
            <mat-error
              *ngIf="beneficiaryDescriptionControl?.hasError('pattern')"
              >Use only letters and numbers</mat-error
            >
            <div class="counter">
              <label
                >{{ benDescInput.value.length }} /
                {{ descriptionMaxLength }}</label
              >
            </div>
          </mat-form-field>
        </div>

        <!-- RECURRING TRANSFER -->
        <ng-container *ngIf="isRecurringTransfer">
          <mat-checkbox formControlName="isRecurring" color="primary"
            >Recurring transfer</mat-checkbox
          >
          <div *ngIf="!!isRecurringControl.value" fxLayout="column">
            <label>Repeat this transaction</label>
            <mat-form-field>
              <mat-select formControlName="recurringCycle">
                <mat-option [value]="RecurringCycle.WEEKLY">Weekly</mat-option>
                <mat-option [value]="RecurringCycle.BIWEEKLY"
                  >Biweekly</mat-option
                >
                <mat-option [value]="RecurringCycle.MONTHLY"
                  >Monthly</mat-option
                >
                <mat-option [value]="RecurringCycle.BIANNUALLY"
                  >Biannually</mat-option
                >
                <mat-option [value]="RecurringCycle.ANNUALLY"
                  >Annually</mat-option
                >
              </mat-select>
              <mat-error *ngIf="recurringCycleControl.hasError('required')"
                >Required</mat-error
              >
            </mat-form-field>
          </div>
        </ng-container>

        <button
          *ngIf="!isExternal"
          mat-flat-button
          color="accent"
          [disabled]="!transactionForm!.valid || isProcessingTransfer"
          [appButtonLoader]="isProcessingTransfer"
          (click)="shouldTransfer.emit()"
          data-testid="transfer-button"
        >
          Transfer
        </button>
      </div>

      <!-- RIGHT COLUMN -->
      <div
        class="right-column"
        [fxFlexOrder.lt-sm]="isExternal ? 2 : 1"
        fxFlexOrder="2"
      >
        <!-- E2E -->
        <div *ngIf="beneficiaryName" class="beneficiary-container">
          <div fxLayout fxLayoutGap="5px">
            <span>Beneficiary:</span>
            <span class="beneficiary-name">{{ beneficiaryName }}</span>
          </div>
          <div class="e2e-fees">
            <div fxLayout fxLayoutGap="5px" class="fee">
              <span>Indicative Transfer Fee:</span>
              <span class="fee-value"
                >{{ feeControl?.value?.calculatedAmount | amount }} EUR</span
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="isWrongBeneficiary || isSameBeneficiary"
          class="wrong-beneficiary"
        >
          <strong>
            <span *ngIf="isWrongBeneficiary"
              >Sorry, this phone number is not registered<br />
              with eCREDO.</span
            >
            <span *ngIf="isSameBeneficiary"
              >Sorry, this phone number belongs to you.</span
            >
          </strong>
          <div>
            Please, double-check the phone number<br />
            or contact the beneficiary.
          </div>
        </div>

        <!-- SEPA/SWIFT -->
        <ng-container *ngIf="isExternal && accountsGroup.valid">
          <!-- Show iban/bic details if bic control is (enabled and valid) or (disabled)
        because bicControl.valid is false when disabled, so it won't show details if i check for validity -->
          <div
            *ngIf="
              (ibanDetails?.bank_data?.bank || bicDetails?.bankName) &&
              ((!bicControl.disabled && bicControl.valid) ||
                bicControl.disabled)
            "
            class="beneficiary-container bank-name"
          >
            <span class="beneficiary-name">Bank Name</span><br />
            {{ ibanDetails?.bank_data?.bank || bicDetails?.bankName }}
          </div>
          <div
            *ngIf="
              (ibanDetails?.bank_data?.address || bicDetails?.bankAddress) &&
              ((!bicControl.disabled && bicControl.valid) ||
                bicControl.disabled)
            "
            class="beneficiary-container bank-name"
          >
            <span class="beneficiary-name">Bank Address</span><br />
            {{ ibanDetails?.bank_data?.address || bicDetails?.bankAddress }}
          </div>
          <div fxLayout="column" class="bic">
            <label for="bic">Bank SWIFT / BIC</label>
            <mat-form-field>
              <input
                matInput
                formControlName="bic"
                id="bic"
                maxlength="11"
                data-testid="bank-swift-bic-input"
              />
              <mat-error *ngIf="bicControl.hasError('required')"
                >Required</mat-error
              >
              <mat-error *ngIf="bicControl.hasError('pattern')"
                >Wrong value</mat-error
              >
              <mat-error *ngIf="bicControl.hasError('bic')"
                >Invalid value</mat-error
              >
            </mat-form-field>
          </div>

          <!-- Intermediary bank details for SWIFT ONLY -->
          <div
            *ngIf="
              isSepaControl.value === false &&
              intermediaryBicDetails?.bankName &&
              intermediaryBicControl.valid
            "
            class="beneficiary-container bank-name interm"
          >
            <span class="beneficiary-name">Intermediary Bank Name</span><br />
            {{ intermediaryBicDetails?.bankName }}
          </div>

          <div
            *ngIf="
              isSepaControl.value === false &&
              intermediaryBicDetails?.bankAddress &&
              intermediaryBicControl.valid
            "
            class="beneficiary-container bank-name"
          >
            <span class="beneficiary-name">Intermediary Bank Address</span
            ><br />
            {{ intermediaryBicDetails?.bankAddress }}
          </div>

          <div
            *ngIf="isSepaControl.value === false"
            fxLayout="column"
            class="bic"
          >
            <label for="intermediaryBic"
              >Intermediary Bank SWIFT / BIC (optional)</label
            >
            <mat-form-field>
              <input
                matInput
                formControlName="intermediaryBic"
                id="intermediaryBic"
                maxlength="11"
                data-testid="intermediary-bank-input"
              />
              <mat-error *ngIf="intermediaryBicControl.hasError('pattern')"
                >Wrong value</mat-error
              >
              <mat-error *ngIf="intermediaryBicControl.hasError('bic')"
                >Invalid value</mat-error
              >
            </mat-form-field>
          </div>

          <div fxLayout="column">
            <label for="beneficiaryName">Beneficiary Name</label>
            <mat-form-field>
              <input
                matInput
                formControlName="beneficiaryName"
                id="beneficiaryName"
                data-testid="beneficiary-name-input"
              />
              <mat-error *ngIf="beneficiaryNameControl.hasError('required')"
                >Required
              </mat-error>
              <mat-error *ngIf="beneficiaryNameControl.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error *ngIf="beneficiaryNameControl?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>

          <div fxLayout="column">
            <label for="creditorAddress1"
              >Beneficiary Address (Street, building number, place)</label
            >
            <mat-form-field>
              <input
                matInput
                formControlName="creditorAddress1"
                id="creditorAddress1"
                data-testid="beneficiary-address-line-1-input"
              />
              <mat-error *ngIf="creditorAddress1Control.hasError('required')"
                >Required
              </mat-error>
              <mat-error *ngIf="creditorAddress1Control.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error *ngIf="creditorAddress1Control?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayout="column">
            <label for="creditorAddress2"
              >Beneficiary Address 2nd line (optional)</label
            >
            <mat-form-field>
              <input
                matInput
                formControlName="creditorAddress2"
                id="creditorAddress2"
                data-testid="beneficiary-address-line-2-input"
              />
              <mat-error *ngIf="creditorAddress2Control.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error *ngIf="creditorAddress2Control?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayout="column">
            <label for="creditorZip">Beneficiary ZIP/Postal code</label>
            <mat-form-field>
              <input
                matInput
                formControlName="creditorZip"
                id="creditorZip"
                data-testid="beneficiary-postal-code-input"
              />
              <mat-error *ngIf="creditorZipControl.hasError('required')"
                >Required
              </mat-error>
              <mat-error *ngIf="creditorZipControl.hasError('pattern')"
                >Invalid character</mat-error
              >
              <mat-error *ngIf="creditorZipControl?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayout="column">
            <label for="creditorCity">Beneficiary City</label>
            <mat-form-field>
              <input
                matInput
                formControlName="creditorCity"
                id="creditorCity"
                data-testid="beneficiary-city-input"
              />
              <mat-error *ngIf="creditorCityControl.hasError('required')"
                >Required
              </mat-error>
              <mat-error *ngIf="creditorCityControl.hasError('pattern')"
                >Invalid character
              </mat-error>
              <mat-error *ngIf="creditorCityControl?.hasError('maxlength')"
                >Too many characters</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayout="column">
            <label for="creditorCountryCode">Beneficiary Country</label>
            <mat-form-field>
              <mat-select
                formControlName="creditorCountryCode"
                id="creditorCountryCode"
                data-testid="beneficiary-country-select"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.code"
                  [attr.data-testid]="
                    'beneficiary-country-of-residence-' +
                    country.code.toLowerCase()
                  "
                  >{{ country.name | titlecase }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <!-- CHARGES -->
          <div class="charges">
            <label for="charges">Charges (if any) debited from:</label>
            <mat-radio-group
              fxLayout="row wrap"
              fxLayoutGap="19px"
              name="charges"
              formControlName="charges"
              data-testid="charges-radio-button"
            >
              <mat-radio-button
                [value]="Charges.BEN"
                data-testid="charges-BEN-radio-button"
              >
                <div>BEN</div>
              </mat-radio-button>
              <mat-radio-button
                [value]="Charges.OUR"
                data-testid="charges-OUR-radio-button"
              >
                <div>OUR</div>
              </mat-radio-button>
              <mat-radio-button
                [value]="Charges.SHA"
                data-testid="charges-SHA-radio-button"
              >
                <div>SHA</div>
              </mat-radio-button>
            </mat-radio-group>
            <div>
              <b>OUR</b> - All charges are paid by the ordering customer<br />
              <b>BEN</b> - All charges are paid by the beneficiary customer<br />
              <b>SHA</b> - All charges are paid shared
            </div>
          </div>

          <div class="details-container">
            <div fxLayout fxLayoutAlign="start center" fxLayoutGap="5px">
              <div class="transfer-type" data-testid="transfer-type-label">
                {{
                  isSepaControl.value
                    ? 'SEPA transfer'
                    : hideTransferSection
                      ? 'SWIFT transfer coming soon!'
                      : 'SWIFT transfer'
                }}
              </div>
              <mat-icon
                *ngIf="isSepaControl.value"
                class="material-icons-outlined"
                color="accent"
                [matTooltip]="tooltips.sepaTooltipText!"
                >info</mat-icon
              >
            </div>
            <div
              fxLayout
              fxLayoutGap="5px"
              class="fee"
              *ngIf="!hideTransferSection"
            >
              <span>Indicative Transfer Fee:</span>
              <span class="fee-value"
                >{{
                  feeControl?.value?.calculatedAmount *
                    toCurrencyControl?.value?.rate | amount
                }}
                {{ toCurrencyControl?.value?.code }}
              </span>
            </div>
          </div>
          <button
            *ngIf="!hideTransferSection"
            mat-flat-button
            color="accent"
            [disabled]="!transactionForm!.valid || isProcessingTransfer"
            [appButtonLoader]="isProcessingTransfer"
            (click)="shouldTransfer.emit()"
            data-testid="transfer-button"
          >
            Transfer
          </button>
        </ng-container>
      </div>
    </div>

    <div *ngIf="isExternal" class="note">
      <b>
        * Please note that the cut-off time for same-day transfers is 15:00 EET
        (Eastern European Time) on business days.
      </b>
    </div>
  </div>
</mat-card>
