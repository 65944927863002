import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { documentValidator } from 'src/app/shared/document.validator';
import { AppDocumentType } from '../../models/document-type.enum';
import { AppDocument } from '../../models/document.model';

@Component({
  selector: 'app-edd-document-form',
  templateUrl: './edd-document-form.component.html',
  styleUrls: ['./edd-document-form.component.scss'],
})
export class EddDocumentFormComponent implements OnInit, OnDestroy {
  @Input() eddDocumentTypes?: any;
  @Input() set documents(val: AppDocument[]) {
    // adds data to predefined controls
    this.documentsControls.forEach((control) =>
      control.setValue({
        ...control.value,
        doc: val.find((doc) => doc.type === control.value.documentType),
      })
    );
    // in EDD display another files because in EDD_SUBMITTED, more documents may be requested from customer
    // creates controls with data for documents with type ADDITIONAL_FILE
    this.addAdditionalDocuments(val);
    this._documents = val;
    this.documentsControls.forEach((control) => {
      if (control.value.doc?.isAccepted) {
        control.disable();
      }
    });
  }
  get documents(): AppDocument[] {
    return this._documents;
  }

  @Output() isComplete = new EventEmitter<boolean>();

  private _documents: AppDocument[] = [];
  private documentsFormSub: Subscription | null = null;

  documentsForm = this.fb.group({
    documents: this.fb.array([
      this.fb.control(
        {
          documentType: AppDocumentType.PROOF_OF_OCCUPATION_STATUS,
        },
        documentValidator(true)
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.SOURCE_OF_WEALTH,
        },
        documentValidator(true)
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.ORIGIN_OF_FUNDS,
        },
        documentValidator(true)
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.DESTINATION_OF_OUTWARD_PAYMENTS,
        },
        documentValidator(true)
      ),
      this.fb.control(
        {
          documentType: AppDocumentType.LETTER_OF_RECOMMENDATION,
        },
        documentValidator(true)
      ),
    ]),
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.documentsFormSub = this.documentsForm.statusChanges
      .pipe(startWith(this.documentsForm.status))
      .subscribe((status) =>
        setTimeout(() => {
          this.isComplete.emit(status === 'VALID');
        })
      );
  }

  ngOnDestroy(): void {
    this.documentsFormSub?.unsubscribe();
  }

  addAnotherFile(): void {
    this.documentsArray.push(
      this.fb.control(
        { documentType: AppDocumentType.ANOTHER_FILE },
        documentValidator()
      )
    );
  }

  removeField(index: number): void {
    this.documentsArray.removeAt(index);
  }

  // Adds controls for documents with ANOTHER_FILE type
  private addAdditionalDocuments(documents: AppDocument[]): void {
    documents
      .filter((doc) => doc.type === AppDocumentType.ANOTHER_FILE)
      .forEach((doc) =>
        this.documentsArray.push(
          this.fb.control(
            {
              doc,
              documentType: doc.type,
            },
            documentValidator()
          )
        )
      );
  }

  get documentsArray(): FormArray {
    return this.documentsForm.get('documents') as FormArray;
  }
  get documentsControls(): FormControl[] {
    return this.documentsArray.controls as FormControl[];
  }
}
