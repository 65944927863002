<div fxLayout="column">
  <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
</div>

<ng-template #emailStep1>
  <ng-container [formGroup]="resetForm">
    <div class="text" fxFlexAlign="center">
      For security reasons, please enter the email address that you have
      registered with your eCREDO account to receive the password reset link.
    </div>
    <div fxLayout="column">
      <label for="email">Email</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="email"
          data-testid="email-input"
          name="email"
        />
        <mat-error
          data-testid="validation-message-box"
          *ngIf="resetForm.get('email')?.hasError('required')"
          >Required</mat-error
        >
        <mat-error
          data-testid="validation-message-box"
          *ngIf="resetForm.get('email')?.hasError('email')"
          >Invalid</mat-error
        >
      </mat-form-field>
    </div>
    <button
      mat-flat-button
      (click)="sendOtp()"
      fxFlexAlign="center"
      class="reset-password-button"
      data-testid="reset-password-button"
      color="accent"
      [disabled]="resetForm.invalid || isSending || errorStatus === 403"
      [appButtonLoader]="isSending"
    >
      Reset password
    </button>
    <div
      *ngIf="errorStatus"
      data-testid="validation-message-box"
      class="reset-password-error"
      [ngSwitch]="errorStatus"
    >
      <ng-container *ngSwitchCase="403">
        Please contact
        <a href="mailto:support@ecredo.com">support&#64;ecredo.com</a> to unlock
        your account and reset your password.
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #emailStep2>
  <div class="content">
    <ng-container [formGroup]="resetForm">
      <div class="title">Two-factor authentication</div>
      <div class="text">
        We’ve just sent you a verification SMS. Please
        <strong>check your mobile phone</strong> for new messages and insert the
        verification code within the next five minutes.
      </div>
      <div fxLayoutAlign="space-between center" class="verification">
        <div fxLayout="column">
          <label for="otp">Verification code</label>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="otp"
              name="otp"
              data-testid="otp-input"
            />
            <mat-error *ngIf="otpControl?.hasError('wrongCode')"
              >Wrong code</mat-error
            >
          </mat-form-field>
        </div>
        <button
          mat-flat-button
          color="accent"
          (click)="reset()"
          [disabled]="isVerifying || errorStatus === 403 || resetForm.invalid"
          [appButtonLoader]="isVerifying"
          class="verify"
          data-testid="verify-button"
        >
          Verify
        </button>
      </div>
      <mat-divider></mat-divider>
      <div class="send-sms">
        <button
          mat-stroked-button
          color="accent"
          class="g-small"
          [disabled]="
            isSendingAgain || errorStatus === 403 || isSendAgainDisabled
          "
          [appButtonLoader]="isSendingAgain"
          (click)="sendOtp(true)"
        >
          Send SMS again
          <span *ngIf="isSendAgainDisabled && errorStatus !== 403"
            >({{ countdown }}s)</span
          >
        </button>
        <img
          *ngIf="smsSentAgain && errorStatus !== 403"
          src="assets/img/accept.svg"
          alt="sent"
          class="sent-again"
        />
        <mat-icon
          *ngIf="errorStatus === 403"
          svgIcon="cancel"
          class="sent-again cancel"
        ></mat-icon>
      </div>
      <div *ngIf="errorStatus === 403" class="reset-password-error">
        Your account has been locked for security reasons. Please contact
        <a href="mailto:support@ecredo.com">support&#64;ecredo.com</a> to unlock
        your account and reset your password.
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #emailStep3>
  <p>
    We’ve just sent you an e-mail. Please check your mailbox and follow the
    instructions.
  </p>
  <p>
    Please note that you will receive an email only if there is an account
    connected to the provided email address.
  </p>
</ng-template>
