import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { CompanyCounterparty } from '../../models/company-counterparty.model';
import { Country } from 'src/app/shared/models/country.model';
import { Occupation } from '../../models/occupation.enum';
import { OnboardingField } from '../../models/onboarding-field.model';
import { OnboardingFieldId } from 'src/app/admin/users/models/onboarding-field-id.enum';
import {
  fullNameValidator,
  getCountryById,
  getStringValue,
} from 'src/app/shared/helpers/various-helpers.helper';

export const atLeastOneCheckboxIsChecked = () => {
  return (group: FormGroup) => {
    if (
      group.value?.isIncomingTransaction === false &&
      group.value?.isOutgoingTransaction === false
    ) {
      return { atLeastOneCheckboxChecked: true };
    } else return null;
  };
};

@Component({
  selector: 'app-counterparty-form',
  templateUrl: './counterparty-form.component.html',
  styleUrls: ['./counterparty-form.component.scss'],
})
export class CounterpartyFormComponent implements OnInit, OnDestroy {
  @Input() index: number = 0; // used for numbering the components
  @Input() countries: Country[] = [];
  @Input() isReviewed: boolean = false;
  @Input() fields: OnboardingField[] = [];
  @Input() set counterparty(val: CompanyCounterparty) {
    this._counterparty = val;
    if (JSON.stringify(val) !== JSON.stringify(this.counterpartyForm.value)) {
      this.counterpartyForm.patchValue(val, { emitEvent: false });
    }
    if (this.counterpartyForm.valid) {
      this.inputsComplete = true;
    }
    // fieldsMap
    this.fieldsMap.clear();
    this.fields
      ?.filter((field) => !!field.comment && field.counterpartyId === val.id)
      .forEach((field) => {
        let commentedValue = field.commentedValue;
        if (field.id.endsWith('.country')) {
          // converts countryId to country name
          commentedValue = this.getCountryById(
            this.countries,
            parseInt(commentedValue)
          );
        }
        // convert boolean
        if (commentedValue === 'true') {
          commentedValue = 'Yes';
        } else if (commentedValue === 'false') {
          commentedValue = 'No';
        }
        this.fieldsMap.set(field.id, {
          comment: field.comment,
          commentedValue,
        });
      });
  }
  get counterparty(): CompanyCounterparty {
    return this._counterparty;
  }
  @Output() counterpartyChange = new EventEmitter<CompanyCounterparty>();
  @Output() counterpartyRemove = new EventEmitter<number>(); // emits counterparty id
  @Output() isComplete = new EventEmitter<boolean>();

  fieldsMap: Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  > = new Map<
    string,
    { comment: string | null; commentedValue: string | null } | null
  >();
  isRemoving: boolean = false;

  OnboardingFieldId = OnboardingFieldId;
  getCountryById = getCountryById;
  getStringValue = getStringValue;

  counterpartyForm: FormGroup = this.fb.group(
    {
      id: [null],
      name: [null, [Validators.required, ...fullNameValidator]],
      country: [null, Validators.required],
      businessActivity: [null, Validators.required],
      isIncomingTransaction: [null],
      isOutgoingTransaction: [null],
    },
    { updateOn: 'change', validators: atLeastOneCheckboxIsChecked() }
  );

  private _inputsComplete: boolean | undefined = undefined;
  private _counterparty!: CompanyCounterparty;
  private counterpartyFormSub: Subscription | undefined = undefined;

  occupations = Object.entries(Occupation);

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    let lastQueueValue: CompanyCounterparty | null = null;
    let savedValue: CompanyCounterparty | null = null;

    // saving Form when values changed (requests are sent one by one, intermediate values are omitted)
    this.counterpartyFormSub = this.counterpartyForm.valueChanges
      .pipe(
        tap((val) => (lastQueueValue = val)),
        concatMap((val) => {
          if (val === lastQueueValue) {
            this.inputsComplete = false; // prevents submitting application when saving in progress or form is invalid
            this.counterpartyChange.emit(val);
            if (this.counterpartyForm.valid) {
              savedValue = val;
              return this.onboardingService
                .updateCompanyCounterparty(this.counterparty.id, val)
                .pipe(
                  tap(() => {
                    if (savedValue === lastQueueValue) {
                      this.inputsComplete = this.counterpartyForm.valid;
                    }
                  })
                );
            } else {
              return of(null);
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        () => {},
        () => this.errorService.showErrorDialog()
      );
  }

  ngOnDestroy(): void {
    this.counterpartyFormSub?.unsubscribe();
  }

  removeCounterparty(counterparty: CompanyCounterparty): void {
    this.inputsComplete = false; // prevents submitting application when removing in progress
    this.isRemoving = true;

    // set as false to trigger removal
    counterparty.isActive = false;

    this.onboardingService
      .updateCompanyCounterparty(counterparty.id, counterparty)
      .subscribe(
        (modifiedCounterparty) => {
          if (modifiedCounterparty) {
            this.counterpartyChange.emit(modifiedCounterparty);
          } else {
            this.counterpartyRemove.emit(counterparty.id);
          }
          this.isRemoving = false;
        },
        () => {
          this.isRemoving = false;
          this.errorService.showErrorDialog();
        }
      );
  }

  // set to true when inputs are saved and valid
  set inputsComplete(val: boolean | undefined) {
    if (val !== undefined) {
      this.isComplete.emit(val);
    }
    this._inputsComplete = val;
  }
  get inputsComplete(): boolean | undefined {
    return this._inputsComplete;
  }
  get nameControl(): AbstractControl | null {
    return this.counterpartyForm.get('name');
  }
  get countryControl(): AbstractControl | null {
    return this.counterpartyForm.get('country');
  }
  get businessActivityControl(): AbstractControl | null {
    return this.counterpartyForm.get('businessActivity');
  }
}
