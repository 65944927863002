import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { interval, Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { AuthService } from 'src/app/login/services/auth.service';

@Component({
  templateUrl: './idle-dialog.component.html',
  styleUrls: ['./idle-dialog.component.scss'],
})
export class IdleDialogComponent implements OnInit, OnDestroy {
  dialogTimeout = 30; // in seconds
  counter: Observable<number> | null = null;
  counterSub: Subscription | null = null;
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<IdleDialogComponent, boolean>, // dialog returns shouldStartIdleCheck boolean value
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // this.counter is observable that returns numbers [30, 29, ..., 1, 0] every second (for dialogTimeout === 30)
    this.counter = interval(1000).pipe(
      take(this.dialogTimeout),
      startWith(-1),
      map((val) => this.dialogTimeout - 1 - val)
    );
    this.counterSub = this.counter.subscribe({
      complete: () => this.onLogout(true),
    });
  }

  ngOnDestroy(): void {
    this.counterSub?.unsubscribe();
  }

  onContinue(): void {
    // refresh token if user selects to stay logged in
    this.authService.refreshToken().subscribe({
      error: () => this.authService.logOut(true), // logout user if fails to refresh token
    });
    this.dialogRef.close(true); // 'true' because it has to start checking idle state again
  }

  onLogout(isAutomaticLogout = false): void {
    this.authService.logOut(isAutomaticLogout);
    this.dialogRef.close(false); // 'false' because it doesn't have to start checking idle state again
    this.dialog.closeAll();
  }
}
