export enum RecurringCycle {
  WEEKLY = 1,
  BIWEEKLY,
  MONTHLY,
  BIANNUALLY,
  ANNUALLY,
}

export const RecurringCycleLabels = new Map<RecurringCycle, string>([
  [RecurringCycle.WEEKLY, 'Weekly'],
  [RecurringCycle.BIWEEKLY, 'Biweekly'],
  [RecurringCycle.MONTHLY, 'Monthly'],
  [RecurringCycle.BIANNUALLY, 'Biannually'],
  [RecurringCycle.ANNUALLY, 'Annually'],
]);

export function getRecurringCycleLabel(
  recurringCycleCode: RecurringCycle
): string {
  return RecurringCycleLabels.get(recurringCycleCode);
}
