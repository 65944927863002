<div class="main">
  <div class="name">
    {{
      value.anotherFileName ||
        (showProofOfOperatingAddress &&
        value.type === AppDocumentType.PROOF_OF_ADDRESS
          ? 'Proof of Operating Address'
          : getDocumentName(value.type))
    }}
  </div>
  <div
    *ngIf="value.expiryDate"
    fxLayoutAlign="space-between center"
    class="expiry-date"
  >
    <span>Document expiry date:</span>
    <span class="date">{{ value.expiryDate | date: 'shortDate' }}</span>
  </div>

  <div
    *ngIf="value.documentNumber"
    fxLayoutAlign="space-between center"
    class="expiry-date"
  >
    <span>Document number:</span>
    <span class="date">{{ value.documentNumber }}</span>
  </div>

  <div
    *ngIf="value.issueCountry"
    fxLayoutAlign="space-between center"
    class="expiry-date"
  >
    <span>Country:</span>
    <span class="date">
      {{ getCountryById(countries, value.issueCountry) }}</span
    >
  </div>

  <div
    *ngIf="value.faceMatchConfidence"
    fxLayoutAlign="space-between center"
    class="expiry-date"
  >
    <span>Face match confidence score:</span>
    <span class="date">{{ value.faceMatchConfidence }}/100</span>
  </div>

  <button
    *ngIf="value.comments.length > 1"
    mat-button
    class="whole-conversation"
    (click)="openWholeConversation()"
  >
    <div fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon class="material-icons-outlined">forum</mat-icon>
      <div>Whole conversation</div>
    </div>
  </button>
  <div
    class="old-rejection-reason"
    *ngIf="value.oldRejectionReason"
    fxLayoutAlign="start"
    fxLayoutGap="12px"
  >
    <div class="rejected">Rejected</div>
    <div class="reason">{{ value.oldRejectionReason }}</div>
  </div>
  <div
    [fxHide]="value.isAccepted !== false"
    fxLayout="column"
    class="explain-reason"
  >
    <label>Explain the reason of rejection</label>
    <mat-form-field>
      <textarea
        matInput
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="3"
        [cdkAutosizeMaxRows]="3"
        [disabled]="disabled || isLoading"
        [ngModel]="value.rejectionReason"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (ngModelChange)="saveRejectionReason($event)"
        required
        #commentInput="matInput"
      ></textarea>
    </mat-form-field>
  </div>

  <div
    *ngIf="value.name"
    class="file-container"
    [class.enable-cursor]="!environment.DISABLE_DOCUMENT_VIEW_DOWNLOAD"
    fxLayoutAlign="start center"
    fxLayoutGap="12px"
    (click)="openFileDialog()"
  >
    <mat-icon svgIcon="file"></mat-icon>
    <div>{{ value.name }}</div>
  </div>
  <div *ngIf="value.reasonWhyNotUploaded" class="file-not-provided">
    {{ value.reasonWhyNotUploaded }}
  </div>

  <div fxLayoutAlign="space-between center" class="action-container">
    <mat-radio-group
      fxLayoutGap="30px"
      [ngModel]="value.isAccepted"
      (ngModelChange)="saveAcceptation($event, radioGroup, commentInput)"
      [disabled]="disabled || isLoading"
      #radioGroup="ngModel"
    >
      <mat-radio-button [value]="true">Accept</mat-radio-button>
      <mat-radio-button
        [disabled]="acceptedDocumentTypes.includes(value.type)"
        [value]="false"
        >Reject</mat-radio-button
      >
    </mat-radio-group>
    <button
      mat-icon-button
      *ngIf="value.name"
      color="accent"
      [disabled]="isDownloading || environment.DISABLE_DOCUMENT_VIEW_DOWNLOAD"
      [appButtonLoader]="isDownloading"
      (click)="downloadFile()"
    >
      <mat-icon *ngIf="!isDownloading" class="material-icons-outlined"
        >get_app</mat-icon
      >
    </button>
  </div>
</div>
