<app-onboarding-layout-new [steps]="steps">
  <ng-container onboardingLayoutTitle>
    <ng-container [ngSwitch]="isReviewed">
      <h1 data-testid="page-title-text" *ngSwitchCase="true">
        Submitted application
      </h1>
      <h1 data-testid="page-title-text" *ngSwitchDefault>Welcome to eCREDO</h1>
    </ng-container>
    <button
      *ngIf="appCompleteForm.valid"
      mat-flat-button
      data-testid="submit-your-application-top-button"
      color="accent"
      (click)="submitApplication()"
    >
      Submit your application
    </button>
  </ng-container>

  <!-- STEP 0 - for reviewed application -->
  <app-reviewed-info-new
    *ngIf="isReviewed"
    [onboardingData]="onboardingData"
  ></app-reviewed-info-new>

  <!-- STEP 0 - Complete the declaration form -->
  <app-expansion-panel-new
    [expanded]="false"
    [name]="isReviewed ? 'Declaration Form' : 'Complete the declaration form'"
    [isComplete]="step0Control.value"
    data-testid="declaration-form"
  >
    <app-onboarding-business-declaration-form
      [onboardingData]="onboardingData"
      [isReviewed]="isReviewed"
      (isComplete)="step0Control.setValue($event)"
    ></app-onboarding-business-declaration-form>
  </app-expansion-panel-new>

  <ng-container *ngIf="step0Control.value">
    <!-- STEP 4 - Fill in the form -->
    <app-expansion-panel-new
      [expanded]="false"
      [name]="isReviewed ? 'Form' : 'Fill in the form'"
      [isComplete]="step4Control.value"
      data-testid="fill-in-form"
    >
      <app-fill-onboarding-business-form-new
        [countries]="countries"
        [onboardingData]="onboardingData"
        [acceptedCountries]="acceptedCountries"
        [isReviewed]="isReviewed"
        [isFirstTimeEDD]="isFirstTimeEDD"
        [verificationAccepted]="verificationAccepted"
        [isSavingDraft]="isSavingDraft"
        (saveDraft)="saveBusinessDraft()"
        (isComplete)="step4Control.setValue($event)"
      ></app-fill-onboarding-business-form-new>
    </app-expansion-panel-new>

    <!-- STEP 5 - Company's Accounts -->
    <app-expansion-panel-new
      [expanded]="false"
      name="Company’s Accounts ({{ accountsNumber }})"
      [isComplete]="step5Control.value"
      data-testid="bank-account-form"
    >
      <app-company-accounts-form
        [countries]="countries"
        [fields]="onboardingData.fields"
        [companyAccounts]="onboardingData.companyAccounts!"
        [isReviewed]="isReviewed"
        (companyAccountsChange)="onboardingData.companyAccounts = $event"
        (isComplete)="step5Control.setValue($event)"
      ></app-company-accounts-form>
    </app-expansion-panel-new>

    <!-- STEP 3 - Company's Counterparties -->
    <app-expansion-panel-new
      [expanded]="false"
      name="Company’s Counterparties ({{ counterpartiesNumber }})"
      [isComplete]="step3Control.value"
      data-testid="counterparties-form"
    >
      <app-company-counterparties-form
        [countries]="countries"
        [fields]="onboardingData.fields"
        [companyCounterparties]="onboardingData.companyCounterparties!"
        [isReviewed]="isReviewed"
        (companyCounterpartiesChange)="
          onboardingData.companyCounterparties = $event
        "
        (isComplete)="step3Control.setValue($event)"
      ></app-company-counterparties-form>
    </app-expansion-panel-new>

    <!-- STEP 1 - Upload the documents -->
    <app-expansion-panel-new
      *ngIf="step4Control.value"
      [expanded]="false"
      [name]="
        isReviewed
          ? 'Uploaded documents'
          : 'Upload the documents (' + step1Progress + ')'
      "
      [isComplete]="step1Control.value"
      class="step-1"
      data-testid="upload-documents-form"
    >
      <div class="desc">
        <div>
          Before starting to use this application, you have to complete the
          necessary documents.<br />
          Once you have filled, printed and signed the required documents, you
          can upload them here. Please make sure that the documents you upload
          satisfy the following requirements:

          <ul>
            <li>Documents uploaded must be in a readable format.</li>
            <li>
              Please ensure the information provided matches with the uploaded
              documents.
            </li>
            <li>All documents should be in English or Greek.</li>
            <li>
              Documents providing proof of address must be issued within the
              last 6 months and in your name. These are:
            </li>
            <ul>
              <li>
                Utility bill (e.g. electricity, water, internet, phone number)
              </li>
              <li>House insurance</li>
              <li>Municipality taxes</li>
              <li>Bank statement</li>
              <li>Affidavit</li>
              <li>Rental agreement (only for legal entities)</li>
              <li>Temporary Residence Permit in EEA</li>
            </ul>
            <li>
              Public documents that are issued by an EU authority, if not in
              English, will require a certified translation to English.
            </li>
            <li>
              Public documents that are issued by a non-EU authority, if not in
              English, will require a certified translation to English and to be
              apostilled.
            </li>
          </ul>
          For companies incorporated abroad, please provide equivalent documents
          and data similar to the above.
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="!verificationAccepted" class="verification">
          <div class="verify">
            Please ensure that you have your proof of operating address document
            ready, and that your camera is enabled.
          </div>
          <button
            mat-flat-button
            color="accent"
            class="verification"
            [disabled]="isGettingVerificationUrl"
            [appButtonLoader]="isGettingVerificationUrl"
            (click)="getVerificationUrl()"
          >
            Verify your address
          </button>
          <mat-divider></mat-divider>
        </div>

        <strong class="speed-up">
          To speed up the process, please upload the correct document into the
          relevant slot below.
        </strong>

        <app-business-documents-form-new
          [verificationAccepted]="verificationAccepted"
          [documents]="onboardingData.documents"
          [isReviewed]="isReviewed"
          [isFirstTimeEDD]="isFirstTimeEDD"
          (isComplete)="step1Control.setValue($event)"
          (progressLabel)="step1Progress = $event"
        ></app-business-documents-form-new>
      </div>
    </app-expansion-panel-new>

    <!-- STEP 2 - Company's Directors -->
    <app-expansion-panel-new
      [expanded]="false"
      name="Company’s Stakeholders and Authorized Contact Person ({{
        directorsNumber
      }})"
      [isComplete]="step2Control.value"
      data-testid="key-persons-form"
    >
      <div class="desc">
        Please provide information for:
        <ul>
          <li>all directors and authorized contact person</li>
          <li>
            UBO/Shareholder(s) holding ≥25%. If not applicable, please provide
            information for all UBO/Shareholder(s) holding ≥10%.
          </li>
        </ul>
      </div>
      <mat-divider></mat-divider>
      <app-company-directors-form-new
        [countries]="countries"
        [fields]="onboardingData.fields"
        [companyDirectors]="onboardingData.companyDirectors!"
        (companyDirectorsChange)="onboardingData.companyDirectors = $event"
        [companyCloseLinks]="onboardingData.companyCloseLinks!"
        (companyCloseLinksChange)="setCloseLinks($event)"
        (companyDirectorCloseLinksChange)="
          onboardingData.companyDirectorCloseLinks = $event
        "
        [companyDirectorCloseLinks]="onboardingData.companyDirectorCloseLinks!"
        [isReviewed]="isReviewed"
        [isEDD]="isEDD"
        [isFirstTimeEDD]="isFirstTimeEDD"
        (referenceId)="referenceIdChanged($event)"
        (dialogRef)="dialogRefChanged($event)"
        (isComplete)="step2Control.setValue($event)"
      ></app-company-directors-form-new>
    </app-expansion-panel-new>

    <!-- STEP 3 - Company's Company Group Members -->
    <app-expansion-panel-new
      [expanded]="false"
      name="Company’s Group Members ({{ groupMembersNumber }})"
      [isComplete]="step6Control.value"
      data-testid="group-members-form"
    >
      <app-company-group-members-form
        [countries]="countries"
        [fields]="onboardingData.fields"
        [companyGroupMembers]="onboardingData.companyGroupMembers!"
        [isReviewed]="isReviewed"
        (companyGroupMembersChange)="
          onboardingData.companyGroupMembers = $event
        "
        (isComplete)="step6Control.setValue($event)"
      ></app-company-group-members-form>
    </app-expansion-panel-new>

    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between center"
      fxLayoutGap="10px"
    >
      <!-- first time in EDD, applicant only needs to add close links, which are 
       saved in the close links component, so don't display this save button -->
      <button
        *ngIf="!isFirstTimeEDD"
        mat-flat-button
        color="accent"
        fxFlexAlign="start"
        fxFlexAlign.lt-sm="center"
        data-testid="save-as-draft-button"
        (click)="saveBusinessDraft()"
        [appButtonLoader]="isSavingDraft"
        [disabled]="isSavingDraft"
      >
        Save
      </button>
      <button
        *ngIf="appCompleteForm.valid"
        mat-flat-button
        color="accent"
        data-testid="submit-your-application-bottom-button"
        fxFlexAlign="end"
        fxFlexAlign.lt-sm="center"
        (click)="submitApplication()"
      >
        Submit your application
      </button>
    </div>
  </ng-container>
</app-onboarding-layout-new>
