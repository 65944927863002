<div class="main">
  <h1>Transfer confirmation</h1>
  <mat-card>
    <app-account-list-item
      [account]="fromAccount"
      [currency]="fromAccount.ccy"
      [isBalanceHidden]="!!transactionId"
    ></app-account-list-item>
    <mat-card-content>
      <!-- TRANSFER TYPE (shown for SEPA/SWIFT transfer) -->
      <div
        *ngIf="isExternal"
        fxLayout
        fxLayoutAlign="start center"
        fxLayoutGap="5px"
        class="transfer-type"
      >
        <div>{{ isSepa ? 'SEPA' : 'SWIFT' }} transfer</div>
        <mat-icon
          *ngIf="isSepa"
          class="material-icons-outlined"
          color="accent"
          [matTooltip]="tooltips.sepaTooltipText!"
          >info</mat-icon
        >
      </div>

      <div fxLayout fxLayoutGap="36px">
        <div class="section">
          <div>Amount</div>
          <div class="amount">{{ amount | amount }} {{ currency }}</div>
        </div>
        <div *ngIf="fee !== undefined">
          <div>Fee</div>
          <div class="amount">
            {{
              (isExternal
                ? fee.calculatedAmount * toCurrency.rate
                : fee.calculatedAmount
              ) | amount
            }}
            {{ isExternal ? toCurrency?.code : 'EUR' }}
          </div>
        </div>
      </div>

      <div class="section" *ngIf="charges">
        <div>Charges option</div>
        <div class="description">{{ charges.toUpperCase() }}</div>
      </div>

      <div class="section">
        <div>{{ !isBetween ? 'Description for Payer' : 'Description' }}</div>
        <div class="description">{{ payerDescription }}</div>
      </div>
      <div class="section" *ngIf="beneficiaryDescription">
        <div>Description for Beneficiary</div>
        <div class="description">{{ beneficiaryDescription }}</div>
      </div>

      <div class="section" *ngIf="executionDate">
        <div>Value Date</div>
        <div class="description">{{ executionDate | date: 'dd/MM/yyyy' }}</div>
      </div>

      <div class="section" *ngIf="transferBody?.isRecurring">
        <div>Recurring Transfer</div>
        <div class="description">
          {{ getRecurringCycleLabel(transferBody?.recurringCycle) }}
        </div>
      </div>

      <mat-divider></mat-divider>
      <h1>To</h1>
      <div *ngIf="toAccount" class="to-account">
        <!-- CARD -->
        <ng-container *ngIf="isCardAccount(toAccount)">
          <div class="section description">
            <!-- TODO code to card number -->
            Card - {{ toAccount.code.slice(-4) }}
          </div>
          <div class="section">
            <!-- <div>Card holder</div>
            <div class="description">
               TODO card owner
              {{ toAccount.owner.firstname | titlecase }}
              {{ toAccount.owner.lastname | titlecase }}
            </div> -->
          </div>
        </ng-container>

        <!-- IBAN -->
        <ng-container *ngIf="isIbanAccount(toAccount)">
          <div class="section description">IBAN Account</div>
        </ng-container>

        <!-- WALLET -->
        <ng-container *ngIf="isWalletAccount(toAccount)">
          <div class="section description">{{ toAccount?.ccy }} Wallet</div>
        </ng-container>
      </div>

      <ng-container *ngIf="toName && toPhoneNumber">
        <div class="description">{{ toName }}</div>
        <div class="description">{{ toPhoneNumber }}</div>
      </ng-container>

      <ng-container *ngIf="isExternal">
        <div class="section description">
          <div>{{ beneficiaryName }}</div>
          <div>
            {{ address1 }}<span *ngIf="address2"> {{ address2 }}</span
            >, {{ zipCode }} {{ city }}
          </div>
          <div>{{ country }}</div>
        </div>
        <div class="section">
          <div>IBAN</div>
          <div class="description">{{ ibanOrAccountNumber }}</div>
        </div>
        <div class="section">
          <div>Bank SWIFT / BIC</div>
          <div class="description">{{ bic }}</div>
        </div>
        <div class="section" *ngIf="bankName">
          <div>Bank Name</div>
          <div class="description">{{ bankName }}</div>
        </div>
        <div class="section" *ngIf="intermediaryBic">
          <div>Intermediary Bank SWIFT / BIC</div>
          <div class="description">{{ intermediaryBic }}</div>
        </div>
        <div class="section" *ngIf="intermediaryBankName">
          <div>Intermediary Bank Name</div>
          <div class="description">{{ intermediaryBankName }}</div>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="!!transactionId">
        <!-- TRANSFER SUCCESS -->
        <div
          *ngSwitchCase="true"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          class="confirmed-container"
        >
          <div class="header">Transfer confirmed</div>
          <div class="text">
            Your transfer order is now processed and will be effective shortly.
          </div>

          <app-transfer-save-favourite
            *ngIf="canSaveToFavourites"
            [transferBody]="transferBody"
          >
          </app-transfer-save-favourite>

          <button
            mat-stroked-button
            color="accent"
            class="g-medium"
            (click)="cancel.emit({ shouldReload: true, restartProcess: false })"
            data-testid="make-another-transfer-button"
          >
            Make another transfer
          </button>
        </div>

        <!-- TRANSFER BUTTONS -->
        <div
          *ngSwitchDefault
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          class="buttons-container"
        >
          <ng-container *ngIf="hasOtp">
            <mat-divider></mat-divider>
            <div class="sms-text">
              We’ve just sent you a confirmation SMS.<br />
              <strong>Please check your mobile phone</strong><br />
              and insert the confirmation code.
            </div>
          </ng-container>

          <div fxLayout="column" fxLayoutAlign="center center">
            <div
              fxLayout
              fxLayoutAlign="space-between center"
              fxLayoutGap="20px"
            >
              <div fxLayout="column" *ngIf="hasOtp">
                <label>Confirmation code</label>
                <mat-form-field>
                  <input
                    matInput
                    [formControl]="otpControl"
                    data-testid="confirmation-code-input"
                  />
                  <mat-error data-testid="confirmation-code-error-wrong-code"
                    >Wrong code</mat-error
                  >
                </mat-form-field>
              </div>

              <button
                mat-flat-button
                color="accent"
                [disabled]="(hasOtp && !otpControl.valid) || isTransferring"
                [appButtonLoader]="isTransferring"
                (click)="confirmTransfer()"
                data-testid="confirm-transfer-button"
              >
                Confirm
              </button>
            </div>

            <!-- e2e or external -->
            <div *ngIf="(toName && toPhoneNumber) || isExternal">
              <button
                mat-stroked-button
                color="accent"
                class="g-small"
                data-testid="send-sms-again-button"
                [disabled]="isSendingAgain || isSendAgainDisabled"
                [appButtonLoader]="isSendingAgain"
                (click)="reSendSms()"
              >
                Send SMS again
                <span *ngIf="isSendAgainDisabled">({{ countdown }}s)</span>
              </button>
              <img
                *ngIf="smsSentAgain"
                src="assets/img/accept.svg"
                alt="sent"
                class="sent-again"
              />
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="15px">
            <button
              mat-flat-button
              color="accent"
              [disabled]="isTransferring"
              (click)="back.emit()"
              data-testid="back-transfer-button"
            >
              Back
            </button>

            <button
              mat-flat-button
              color="accent"
              [disabled]="isTransferring"
              (click)="
                cancel.emit({ shouldReload: false, restartProcess: false })
              "
              data-testid="cancel-transfer-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
