import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { UserNotification } from 'src/app/shared/header/models/userNotification.model';
import { NotificationService } from 'src/app/shared/header/notification.service';
import { ServerSentEventType } from 'src/app/shared/models/server-sent-event-type.enum';
import { UserEventsService } from 'src/app/shared/services/user-events.service';

@Component({
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications!: UserNotification[];
  eventsSubscription?: Subscription;
  isLoading: boolean = false;

  constructor(
    private userEventsService: UserEventsService,
    private notificationService: NotificationService,
    private errorService: ErrorService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  displayedColumns: string[] = ['title', 'content', 'dateCreated'];

  ngOnInit(): void {
    // if new notification arrives re-load all notifications
    this.eventsSubscription = this.userEventsService.userEventsObservable
      .pipe(
        map((ev) => JSON.parse(ev)),
        filter((ev) => ev.type === ServerSentEventType.NEW_NOTIFICATION)
      )
      .subscribe(() => {
        this.ngZone.run(() => {
          this.getNotifications();
        });
      });
    this.getNotifications();
  }

  // redirect to the onboarding/verified application specified in the notification
  redirectToApplication(row: UserNotification) {
    const userId = this.getUserIdFromNotification(row.content);
    if (this.hasOnboardingTitle(row.title)) {
      this.router.navigate([`/admin/users/ongoing-registration-new/${userId}`]);
    } else if (this.hasApplicationVerifiedTitle(row.title)) {
      this.router.navigate([`/admin/users/registered-users/${userId}`]);
    }
  }

  containsActionRequired(content: string) {
    return content.includes('[Action Required]');
  }

  withoutActionRequired(content: string) {
    return content.replace(/\[Action Required\]/g, '');
  }

  // extract user id from the notification
  getUserIdFromNotification(content: string) {
    return content.split('#')[1];
  }

  hasOnboardingTitle(title: string) {
    return title.includes('Onboarding Action');
  }

  hasApplicationVerifiedTitle(title: string) {
    return title.includes('Application Verified Action');
  }

  // redirection to application is allowed only for notifications with the following titles and with a userId
  hasLinkToApplication(row: UserNotification) {
    return (this.hasOnboardingTitle(row.title) ||
      this.hasApplicationVerifiedTitle(row.title)) &&
      this.getUserIdFromNotification(row.content) !== undefined
      ? 'Learn more'
      : '';
  }

  getNotifications() {
    this.isLoading = true;
    this.notificationService.getUserNotifications().subscribe(
      (n) => {
        this.notifications = n;
        this.isLoading = false;
      },
      () => {
        this.errorService.showErrorDialog();
        this.isLoading = false;
      }
    );
  }

  changeReadStatus(id: any) {
    this.notificationService.changeUserNotificationStatus(id).subscribe(
      () => {
        this.notifications.map((n) => {
          if (n.numId === id) n.isUnread = !n.isUnread;
        });
      },
      () => {
        this.errorService.showErrorDialog();
      }
    );
  }

  countNumUnreadNotifications() {
    return this.notifications
      ? this.notifications.filter((n) => n.isUnread).length
      : 0;
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }
}
