import { AppDocumentType } from '../../../../../onboarding-new/models/document-type.enum';

export const selectDocumentNamesMap = new Map<AppDocumentType, string>([
  [AppDocumentType.IDENTITY_CARD, 'Identity card'],
  [AppDocumentType.PASSPORT, 'Passport'],
  [AppDocumentType.PROOF_OF_ADDRESS, 'Proof of Address'],
  [
    AppDocumentType.CERTIFICATE_OF_INCORPORATION,
    'Certificate of Incorporation',
  ],
  [
    AppDocumentType.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
    'Memorandum and Articles of Association',
  ],
  [
    AppDocumentType.LATEST_FINANCIAL_STATEMENTS,
    `Latest Audited Financial Statements of the last 2 (two) years or if not available, the Last Financial Statements available or Draft Account signed by the company's directors`,
  ],
  [AppDocumentType.CERTIFICATE_OF_SHAREHOLDERS, 'Certificate of Shareholders'],
  [AppDocumentType.CERTIFICATE_OF_DIRECTORS, 'Certificate of Directors'],
  [
    AppDocumentType.LETTER_OF_RECOMMENDATION,
    'Letter of recommendation from a third party (i.e. reputable financial institution or EU professional) confirming professional reputation and integrity',
  ],
  [AppDocumentType.SOURCE_OF_WEALTH, 'Source of Wealth (accumulated assets)'],
  [AppDocumentType.ORIGIN_OF_FUNDS, 'Source of Incoming Funds'],
  [
    AppDocumentType.DESTINATION_OF_OUTWARD_PAYMENTS,
    'Destination of Outward Payments',
  ],
  [AppDocumentType.PROOF_OF_OCCUPATION_STATUS, 'Proof of Occupation Status'],
  [
    AppDocumentType.CERTIFICATE_OF_REGISTERED_OFFICE,
    'Certificate of Registered Office',
  ],
  [
    AppDocumentType.BOARD_RESOLUTION,
    'Board resolution indicating the signatories of the company',
  ],
  [AppDocumentType.LICENSE, 'License (optional)'],
  [AppDocumentType.FACE, 'Face'],
  [AppDocumentType.COMPANY_STRUCTURE, `Company's Structure (if available)`],
]);
