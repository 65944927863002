import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Country } from 'src/app/shared/models/country.model';
import { OnboardingData } from '../../models/onboarding-data.model';
import {
  OnboardingSubmitDialogModel,
  OnboardingSubmitDialogNewComponent,
} from '../onboarding-submit-dialog/onboarding-submit-dialog.component';
import { Step } from '../../stepper/stepper.component';
import { AppDocumentType } from '../../models/document-type.enum';
import { EventType } from '../../models/event-type.enum';
import {
  VerificationDialogComponent,
  VerificationDialogData,
} from '../verification-dialog/verification-dialog.component';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import { OnboardingService } from '../../onboarding.service';
import { of, Subscription } from 'rxjs';
import { map, filter, concatMap } from 'rxjs/operators';
import { ServerSentEventType } from 'src/app/shared/models/server-sent-event-type.enum';
import { UserEventsService } from 'src/app/shared/services/user-events.service';
import { FillOnboardingBusinessFormNewComponent } from '../fill-onboarding-business-form/fill-onboarding-business-form.component';

@Component({
  selector: 'app-onboarding-business-form-new',
  templateUrl: './onboarding-business-form.component.html',
  styleUrls: ['./onboarding-business-form.component.scss'],
})
export class OnboardingBusinessFormNewComponent implements OnInit, OnDestroy {
  @Input() onboardingData!: OnboardingData;
  @Input() countries: Country[] = [];
  @Input() acceptedCountries: Country[] = [];
  @Input() isReviewed!: boolean; // true when userState === ADDITIONAL_INFORMATION_REQUESTED or EDD
  @Input() steps: Step[] = [];
  @Input() isEDD!: boolean; // true when userState === UserState.EDD
  @Input() isFirstTimeEDD!: boolean; // true when userState === UserState.EDD and dtEddFormSubmitted is empty (reached EDD for the first time)
  @Output() reloadUser = new EventEmitter<void>();
  @ViewChild(FillOnboardingBusinessFormNewComponent)
  fillOnboardingBusinessForm!: FillOnboardingBusinessFormNewComponent;

  step1Progress = '';

  appCompleteForm: FormGroup = this.fb.group({
    step0: [false, Validators.requiredTrue],
    step1: [false, Validators.requiredTrue],
    step2: [false, Validators.requiredTrue],
    step3: [false, Validators.requiredTrue],
    step4: [false, Validators.requiredTrue],
    step5: [false, Validators.requiredTrue],
    step6: [false, Validators.requiredTrue],
  });

  isGettingVerificationUrl: boolean = false;
  verificationAccepted: boolean = false;
  isSavingDraft: boolean = false;
  referenceId!: string;
  dialogRef!: MatDialogRef<VerificationDialogComponent>;
  eventsSubscription?: Subscription;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private errorService: ErrorService,
    private userEventsService: UserEventsService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    const doc = this.onboardingData.documents.find(
      (d) => d.type === AppDocumentType.PROOF_OF_ADDRESS
    );
    // show that verification was done only if address doc exists (in case document failed to be saved)
    if (
      doc !== undefined &&
      doc.isAccepted &&
      this.onboardingData.onboardingVerificationDone
    ) {
      this.verificationAccepted = true;
    }
    // listen to verification status events, in case entity/stakeholder verification not done
    this.subscribeToVerificationStatusEvents();
  }

  subscribeToVerificationStatusEvents() {
    this.eventsSubscription = this.userEventsService.userEventsObservable
      .pipe(
        map((ev) => JSON.parse(ev)),
        filter((ev) => ev.type === ServerSentEventType.VERIFICATION_STATUS)
      )
      .subscribe((ev) => {
        // update UI in real-time
        this.ngZone.run(() => {
          if (
            ev.verificationStatus === EventType.VERIFICATION_ACCEPTED ||
            ev.verificationStatus === EventType.VERIFICATION_STATUS_CHANGED
          ) {
            this.reloadUser.emit();
          }
        });
      });
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.eventsSubscription?.unsubscribe();
  }

  referenceIdChanged(referenceId: string) {
    this.referenceId = referenceId;
  }

  // used for emitting verification dialog reference
  dialogRefChanged(dialogRef: MatDialogRef<VerificationDialogComponent>) {
    this.dialogRef = dialogRef;
  }

  async saveBusinessDraft(): Promise<void> {
    this.isSavingDraft = true;

    // Save corporate form draft
    this.fillOnboardingBusinessForm.saveCorporateForm();

    // Initialize an observable for executing all updates sequentially and ensure completion handling
    let updateObservable$ = of(null);

    // Sequentially save company accounts
    const companyAccounts = this.onboardingData.companyAccounts;
    updateObservable$ = companyAccounts.reduce((acc$, companyAccount) => {
      return acc$.pipe(
        concatMap(() =>
          this.onboardingService.updateCompanyAccount(
            companyAccount.id,
            companyAccount
          )
        )
      );
    }, updateObservable$);

    // Sequentially process company counterparties
    const companyCounterparties = this.onboardingData.companyCounterparties;
    updateObservable$ = companyCounterparties.reduce(
      (acc$, companyCounterparty) => {
        return acc$.pipe(
          concatMap(() =>
            this.onboardingService.updateCompanyCounterparty(
              companyCounterparty.id,
              companyCounterparty
            )
          )
        );
      },
      updateObservable$
    );

    // Sequentially process company directors
    const companyDirectors = this.onboardingData.companyDirectors;
    updateObservable$ = companyDirectors.reduce((acc$, companyDirector) => {
      return acc$.pipe(
        concatMap(() =>
          this.onboardingService.updateCompanyDirectorOrShareholder(
            companyDirector.id,
            {
              ...companyDirector,
              phoneNumber: companyDirector.phoneNumber
                ? companyDirector.phoneNumber.hasOwnProperty('e164Number')
                  ? companyDirector.phoneNumber.e164Number
                  : companyDirector.phoneNumber
                : null,
            }
          )
        )
      );
    }, updateObservable$);

    // Sequentially process group members
    const groupMembers = this.onboardingData.companyGroupMembers;
    updateObservable$ = groupMembers.reduce((acc$, groupMember) => {
      return acc$.pipe(
        concatMap(() =>
          this.onboardingService.updateCompanyGroupMember(
            groupMember.id,
            groupMember
          )
        )
      );
    }, updateObservable$);

    // Execute all updates sequentially and ensure completion handling
    updateObservable$.pipe().subscribe(
      () => {
        this.isSavingDraft = false;
        this.fillOnboardingBusinessForm?.corporateForm.markAllAsTouched(); // show to user the incomplete fields
      },
      () => {
        this.isSavingDraft = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  async getVerificationUrl(): Promise<void> {
    this.isGettingVerificationUrl = true;
    this.onboardingService.getVerificationUrl(false, true).subscribe(
      (res) => {
        this.isGettingVerificationUrl = false;
        // open dialog to not allow data changes
        this.referenceId = res.reference_id;
        this.dialogRef = this.dialog.open<
          VerificationDialogComponent,
          VerificationDialogData
        >(VerificationDialogComponent, {
          width: '600px',
          height: '700px',
          disableClose: true,
          data: {
            src: res.verification_url,
          },
        });
      },
      () => {
        this.isGettingVerificationUrl = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  submitApplication(): void {
    this.dialog.open<
      OnboardingSubmitDialogNewComponent,
      OnboardingSubmitDialogModel
    >(OnboardingSubmitDialogNewComponent, {
      panelClass: 'dialog-with-close-button',
      width: '690px',
      disableClose: true,
      data: {
        directorsNumber: this.directorsNumber,
        counterpartiesNumber: this.counterpartiesNumber,
        groupMembersNumber: this.groupMembersNumber,
        accountsNumber: this.accountsNumber,
      },
    });
  }

  setCloseLinks(event: any) {
    // assign it like that to trigger ngOnChanges in every child director so each director emits isComplete to parent
    this.onboardingData.companyCloseLinks = Object.assign([], event);
  }

  get directorsNumber(): number {
    return this.onboardingData.companyDirectors!.length;
  }
  get counterpartiesNumber(): number {
    return this.onboardingData.companyCounterparties?.length || 0;
  }
  get accountsNumber(): number {
    return this.onboardingData.companyAccounts?.length || 0;
  }
  get groupMembersNumber(): number {
    return this.onboardingData.companyGroupMembers?.length || 0;
  }

  get step0Control(): FormControl {
    return this.appCompleteForm.get('step0') as FormControl;
  }
  get step1Control(): FormControl {
    return this.appCompleteForm.get('step1') as FormControl;
  }
  get step2Control(): FormControl {
    return this.appCompleteForm.get('step2') as FormControl;
  }
  get step3Control(): FormControl {
    return this.appCompleteForm.get('step3') as FormControl;
  }
  get step4Control(): FormControl {
    return this.appCompleteForm.get('step4') as FormControl;
  }
  get step5Control(): FormControl {
    return this.appCompleteForm.get('step5') as FormControl;
  }
  get step6Control(): FormControl {
    return this.appCompleteForm.get('step6') as FormControl;
  }
}
