import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Program } from '../../../../../login/models/program.enum';
import { RegisteredUsersService } from '../../registered-users.service';
import { RegisteredUserDocument } from '../../models/registered-user-document.model';
import { getDocumentName } from 'src/app/shared/helpers/various-helpers.helper';
import { AppDocumentType } from '../../../../../onboarding-new/models/document-type.enum';
import { selectDocumentNamesMap } from '../shared/selectDocumentNamesMap';
import { forkJoin } from 'rxjs';
import { AllDocumentsFormComponent } from './components/all-documents-form/all-documents-form.component';
import { ComponentCanDeactivate } from '../../../../../guards/pending-changes-guard';
import { CompanyDirector } from 'src/app/onboarding-new/models/company-director.model';

@Component({
  selector: 'app-registered-user-documents',
  templateUrl: './registered-user-documents.component.html',
  styleUrls: ['./registered-user-documents.component.scss'],
})
export class RegisteredUserDocumentsComponent
  implements ComponentCanDeactivate, OnInit
{
  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.documentsForm ? this.documentsForm?.canDeactivate() : true;
  }

  @Input() userId!: number;
  @Input() tabIndex?: number;
  @ViewChild(AllDocumentsFormComponent)
  documentsForm?: AllDocumentsFormComponent;

  documents?: RegisteredUserDocument[];
  displayedDocumentOptions: string[] = [];
  isRowColored: boolean = false;
  isSecondRowColored: boolean = false;
  isLoadingTable: boolean = false;
  companyDirectors?: CompanyDirector[];
  isCorporateUser: boolean = false;

  constructor(private registeredUsersService: RegisteredUsersService) {}

  ngOnInit() {
    this.isCorporateUser = false;
    this.displayedDocumentOptions = [
      ...[
        AppDocumentType.IDENTITY_CARD,
        AppDocumentType.PASSPORT,
        AppDocumentType.PROOF_OF_ADDRESS,
      ].map((documentType) => selectDocumentNamesMap.get(documentType)!),
    ];
    forkJoin([
      this.registeredUsersService
        .getRegisteredUser(this.userId)
        .subscribe((registeredUser) => {
          this.companyDirectors = registeredUser.companyDirectors;
          if (
            registeredUser.program === Program.RUBY ||
            registeredUser.program === Program.ONYX
          ) {
            this.isCorporateUser = true;
            this.displayedDocumentOptions =
              this.displayedDocumentOptions.concat(
                ...[
                  AppDocumentType.CERTIFICATE_OF_INCORPORATION,
                  AppDocumentType.MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
                  AppDocumentType.LATEST_FINANCIAL_STATEMENTS,
                  AppDocumentType.CERTIFICATE_OF_SHAREHOLDERS,
                  AppDocumentType.CERTIFICATE_OF_DIRECTORS,
                  AppDocumentType.CERTIFICATE_OF_REGISTERED_OFFICE,
                  AppDocumentType.BOARD_RESOLUTION,
                  AppDocumentType.LICENSE,
                  AppDocumentType.COMPANY_STRUCTURE,
                ].map(
                  (documentType) => selectDocumentNamesMap.get(documentType)!
                )
              );
          }
        }),
      this.getDocuments(),
    ]);
  }

  private getDocuments(): void {
    this.isLoadingTable = true;

    this.registeredUsersService
      .getDocuments(this.userId)
      .subscribe((documents) => {
        this.documents = documents.map((document) => ({
          ...document,
          type: getDocumentName(+document.type)!,
        }));

        this.documents.filter(
          (document) =>
            document.anotherFileName &&
            this.displayedDocumentOptions.push(document.anotherFileName)
        );

        this.displayedDocumentOptions = [
          ...new Set(this.displayedDocumentOptions),
        ];
        this.isLoadingTable = false;
      });
  }

  onRefreshTable(type: AppDocumentType): void {
    this.getDocuments();
    this.isRowColored = true;
    if (type === AppDocumentType.IDENTITY_CARD) this.isSecondRowColored = true;
    setInterval(() => {
      this.isRowColored = false;
      this.isSecondRowColored = false;
    }, 4000);
  }
}
