import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Used for FileInputComponent
export function documentValidator(isOptional: boolean = false): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    const hasDocument = formControl.value?.doc?.location != null;
    const hasRejectionReason = formControl.value?.doc?.rejectionReason != null;

    //  if document has a rejection reason but missing, it's invalid
    if (hasRejectionReason && !hasDocument) {
      return { document: true };
    }

    // if document is required (not optional) but missing, it's invalid
    if (!isOptional && !hasDocument) {
      return { document: true };
    }

    return null;
  };
}
