import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountType } from 'src/app/dashboard/shared/account-type.enum';
import { Transfer } from '../models/transfer.model';
import {
  COUNTDOWN_IN_SECONDS,
  MAX_ATTEMPTS,
  MAX_SMS_RESENDS,
  tooltips,
} from 'src/app/shared/helpers/various-helpers.helper';
import { getRecurringCycleLabel } from '../models/recurring-cycle.enum';
import { Charges } from '../enum/charges.enum';

@Component({
  selector: 'app-transfer-confirmation',
  templateUrl: './transfer-confirmation.component.html',
  styleUrls: ['./transfer-confirmation.component.scss'],
})
export class TransferConfirmationComponent {
  @Input() fromAccount!: AccountDetails;
  @Input() toAccount?: AccountDetails;
  // e2e Now
  @Input() toName?: string;
  @Input() toPhoneNumber?: string;
  // SEPA/SWIFT
  @Input() isExternal?: boolean;
  @Input() isBetween?: boolean;
  @Input() currency: string = 'EUR';
  @Input() toCurrency?: any;
  @Input() beneficiaryName?: string;
  @Input() ibanOrAccountNumber?: string;
  @Input() isSepa?: boolean;
  @Input() bic?: string;
  @Input() bankName?: string;
  @Input() intermediaryBic?: string;
  @Input() intermediaryBankName?: string;
  @Input() executionDate?: string;
  @Input() charges?: Charges;
  @Input() country?: string;
  @Input() address1?: string;
  @Input() address2?: string;
  @Input() zipCode?: string;
  @Input() city?: string;

  @Input() amount!: number;
  @Input() fee?: any;
  @Input() payerDescription!: string;
  @Input() beneficiaryDescription?: string;

  @Input() hasOtp?: boolean = true;
  @Input() canSaveToFavourites: boolean = true;

  @Input() isTransferring: boolean = false;
  @Input() set isInvalidOtp(val: boolean) {
    if (val) {
      this.failedOtpAttempts += 1;
      this.otpControl.setErrors({ invalidOtp: true });
      if (this.failedOtpAttempts >= MAX_ATTEMPTS) {
        this.cancel.emit({ shouldReload: false, restartProcess: true });
      }
    }
  }
  @Input() transactionId?: number;
  @Input() transferBody?: Transfer | null;
  @Input() isSendingAgain: boolean;
  @Input() smsSentAgain: boolean;

  @Output() cancel = new EventEmitter<{
    shouldReload: boolean;
    restartProcess: boolean;
  }>(); // emits true when should reload accounts
  @Output() transfer = new EventEmitter<string | undefined>(); // emits otpCode
  @Output() back = new EventEmitter<void>();
  @Output() resendSms = new EventEmitter<boolean>();

  otpControl = this.fb.control(null, Validators.required);
  tooltips = tooltips;
  failedOtpAttempts: number = 0;
  resendSmsAttempts: number = 0;
  countdown: number = COUNTDOWN_IN_SECONDS;
  isSendAgainDisabled = false;

  getRecurringCycleLabel = getRecurringCycleLabel;

  constructor(private fb: FormBuilder) {}

  confirmTransfer(): void {
    this.transfer.emit(this.otpControl.value);
  }

  reSendSms() {
    if (this.resendSmsAttempts >= MAX_SMS_RESENDS) {
      this.cancel.emit({ shouldReload: false, restartProcess: true });
    } else {
      this.startCountdown();
      this.resendSmsAttempts += 1;
      this.resendSms.emit(true);
    }
  }

  async startCountdown() {
    this.isSendAgainDisabled = true;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.isSendAgainDisabled = false;
        this.smsSentAgain = false;
        this.countdown = COUNTDOWN_IN_SECONDS;
      }
    }, 1000);
  }

  isCardAccount(account: AccountDetails): boolean {
    return account.type === AccountType.CARDS;
  }

  isWalletAccount(account: AccountDetails): boolean {
    return account.type === AccountType.WALLETS;
  }

  isIbanAccount(account: AccountDetails): boolean {
    return account.type === AccountType.IBAN;
  }
}
