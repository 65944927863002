<div fxLayoutAlign="end" class="close">
  <button mat-icon-button mat-dialog-close class="btn">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="verification-container">
    <iframe [src]="src" frameborder="0" allow="camera"></iframe>
  </div>
</mat-dialog-content>
